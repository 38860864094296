<template>
  <wrapper-page @search="search">
    <template v-slot:MainContentHeaderActions>
      <div
        class="d-flex col-md-6 col-sm-12 text-right hidden-xs align-items-center"
      >
        <!-- First Column (col-2) -->
        <div class="pr-0 col-2" style="text-align: right">
          <span @click="sortClients">
            <i :class="sortIconClass" style="color: #4a4c4e"></i>
          </span>
        </div>

        <!-- Second Column (col-4) -->
        <div class="input-group col-10">
          <input
            type="text"
            class="form-control"
            placeholder="Search clients..."
            @input="search($event.target.value)"
          />
          <router-link
            :to="{ name: 'client-details', params: { clientId: 'create' } }"
            class="btn btn-sm btn-success"
            style="margin-left: 10px; height: fit-content; padding: 6px"
            title=""
            :disabled="isSubscriptionReadOnly()"
            >New Client</router-link
          >
        </div>
      </div>
    </template>

    <div class="row clearfix">
      <div
        class="col-lg-3 col-md-6 col-sm-12"
        v-for="model in sortedClients"
        :key="model.id"
      >
        <div class="card">
          <div class="body taskboard" v-bind="cardClass(model)">
            <router-link
              style="display: flex; justify-content: center"
              :to="{ name: 'client-details', params: { clientId: model.id } }"
              ><img
                v-if="!model.image"
                class="card-img-top"
                :src="`https://dummyimage.com/480x300/3b384c/ffffff?text=${model.name}&font=bebas`"
                alt="Card image cap"
              />
              <img
                v-if="model.image"
                class="card-img-top"
                :src="`${model.image}`"
                alt="Card image cap"
                style="height: 120px; width: 120px"
              />
            </router-link>
            <div class="body b-success mt-2">
              <h4 class="card-title">{{ model.name }}</h4>
              <div class="card-subtitle">
                Open projects: {{ model.open_projects }}
              </div>
              <div class="d-flex">
                <small class="text-muted"
                  >Last updated: {{ lastCustomerUpdated(model) }}</small
                >
                <small class="ml-auto text-muted dropdown">
                  <ul class="header-dropdown dropdown">
                    <li class="dropdown">
                      <a
                        href="javascript:void(0);"
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></a>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link
                            :to="{
                              name: 'client-details',
                              params: { clientId: model.id },
                            }"
                            >Client Settings</router-link
                          >
                        </li>
                        <li>
                          <a
                            href="#"
                            :disabled="isSubscriptionReadOnly()"
                            v-on:click="
                              () => {
                                clientIdToBeDeleted = model.id;
                                isDeleteClientClicked = true;
                              }
                            "
                            >Delete Client</a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </small>
              </div>
            </div>
          </div>
        </div>
        <action-confirm-modal
          v-if="isDeleteClientClicked"
          :title="deleteModalProps.title"
          :text="deleteModalProps.text"
          :submitText="deleteModalProps.submitText"
          @close="isDeleteClientClicked = false"
          @submit="
            () => {
              deleteClient();
              isDeleteClientClicked = false;
            }
          "
        />
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import timeMixin from "../mixins/time";
import WrapperPage from "../components/layout/WrapperPage.vue";
import {
  deleteClientById,
  filterClients,
  getClients,
  getClientsOpenProjects,
} from "../apis/clients";
import ActionConfirmModal from "../components/ui/Modals/ActionConfirmModal.vue";

export default {
  name: "ListClientsPage",
  mixins: [timeMixin],
  components: {
    WrapperPage,
    ActionConfirmModal,
  },
  data() {
    return {
      clients: [],
      filterString: "",
      isDeleteClientClicked: false,
      clientIdToBeDeleted: undefined,
      sortState: "asc",
      deleteModalProps: {
        submitText: "Delete client",
        title: "Are you sure you want to delete this client?",
        text: "By deleting this client, you will also permanently delete all associated projects. This action is irreversible!",
      },
    };
  },
  async created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
      return;
    }
    await this.refresh();
  },
  methods: {
    sortClients() {
      // Toggle sorting state on each click
      if (this.sortState === "asc") {
        this.sortState = "desc";
      } else if (this.sortState === "desc") {
        this.sortState = "none";
      } else {
        this.sortState = "asc";
      }
    },
    lastCustomerUpdated(customer) {
      return this.toWorkspaceDateTimeFormat(
        this.getLuxonDateTime(customer.updated_on, true)
      );
    },
    async refresh() {
      this.clients = await getClients();
      if (this.clients.length > 0) {
        this.clients = await getClientsOpenProjects(this.clients);
        console.log(this.clients);
        this.clients.sort((a, b) => a.name - b.name);
        console.log(this.clients);
      }
    },
    cardClass(model) {
      // These classes only have an effect in Dark Mode
      switch (model.status) {
        case "complete":
          return { class: "b-green completed_task" };
        case "incomplete":
          return { class: "b-red incompleted_task" };
        case "progress":
          return { class: "b-orange progress_task" };
        default:
          return {};
      }
    },
    /**
     * Listen to the 'search' event
     */
    search: function (searchString) {
      this.filterString = searchString;
    },
    async deleteClient() {
      if (!this.clientIdToBeDeleted) return;

      try {
        if (!this.$auth.user?.admin) {
          this.notifySuccess("Insufficient Access Privileges");
          return;
        }

        await deleteClientById(this.clientIdToBeDeleted);
        await this.refresh();
        this.notifySuccess("Client deleted successfully");
        this.clientIdToBeDeleted = undefined;
      } catch (err) {
        this.notifyError(err, "Error on deleting client");
      }
    },
  },
  computed: {
    sortedClients() {
      // Clone the filteredClients array to avoid modifying the original data
      const clonedClients = [...this.filteredClients];

      // Sort the clonedClients array based on the current sorting state
      if (this.sortState === "asc") {
        return clonedClients.sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, {
            sensitivity: "base",
          });
        });
      } else if (this.sortState === "desc") {
        return clonedClients.sort((a, b) => {
          return b.name.localeCompare(a.name, undefined, {
            sensitivity: "base",
          });
        });
      } else {
        // No order state, return the clonedClients array as is
        return clonedClients;
      }
    },
    sortIconClass() {
      // Dynamically determine the icon class based on the current sorting state
      if (this.sortState === "asc") {
        return "fa fa-sort-alpha-asc";
      } else if (this.sortState === "desc") {
        return "fa fa-sort-alpha-desc";
      } else {
        return "fa fa-sort"; // Default icon for no order state
      }
    },
    filteredClients() {
      return filterClients(this.clients, this.filterString);
    },
  },
};
</script>

<style scoped>
.card .dropdown {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-bottom: 0px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .card .dropdown {
    margin-top: 15px;
  }
}

.card .dropdown .header-dropdown {
  position: absolute;
  top: -8px;
  right: 0px;
  list-style: none;
}
.card .dropdown .header-dropdown li {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
}
.card .dropdown .header-dropdown li a {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: block;
  padding: 5px 10px;
  color: #57595d;
  font-size: 14px;
}
.card .dropdown .header-dropdown li a.dropdown-toggle:after {
  font-size: 18px;
  border: none;
  content: "\f142";
  font-family: "FontAwesome";
  vertical-align: unset;
  width: 11px;
}
.card .dropdown .header-dropdown li .dropdown-menu {
  border-radius: 0.55rem;
  overflow: hidden;
  right: 0;
  left: auto !important;
  transform: none !important;
  top: 100% !important;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(23, 25, 28, 0.2);
  border: 0;
}
.card .dropdown .header-dropdown li .dropdown-menu li {
  display: block !important;
}
.card .dropdown .header-dropdown li .dropdown-menu li a {
  color: #393d42;
  font-weight: 400;
  padding: 10px;
  white-space: nowrap;
}
.card .dropdown .header-dropdown li .dropdown-menu li a:hover {
  color: #3c89da;
}
</style>
