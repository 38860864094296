<template>
  <Modal
    title="Error: Missing merge tag"
    :show-close-button="true"
    @submit="$emit('close')"
    @close="$emit('close')"
    cancel-text="Close"
    :show-submit-button="false"
    :close-on-outside-click="true"
  >
    <div class="container">
      <p>
        It looks like you've forgotten to include the required review link merge
        tag in your email. Please place the <strong>[review_link]</strong> merge
        tag into your message to share the review link with your client. Your
        email cannot be sent until this tag is included.
      </p>
      <button
        type="button"
        class="btn btn-primary"
        title="Copy Merge Tag"
        @click="copy"
      >
        <small>Copy</small> <span>[review link]</span>
        <small> to clipboard</small>
        <i class="ml-2 fa fa-copy" style="color: #fff"></i>
      </button>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "ReviewLinkMergeTagModal",
  components: { Modal },
  methods: {
    copy() {
      navigator.clipboard.writeText("[review link]");
      this.$emit("close");
    },
  },
};
</script>
